<template>
  <div class="login">
    <section class="form_container">
      <el-scrollbar style="height: 170%">
        <div class="manage_tip">
          <span class="title"
            >问题反馈
            <el-form
              :model="feedbackReq"
              status-icon
              :rules="rules"
              ref="feedback"
              label-width="100px"
              class="feedback"
            >
              <el-form-item label="标题" prop="title">
                <el-input
                  v-model="feedbackReq.title"
                  placeholder="请输入标题"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="问题类型" prop="reason">
                <el-select
                  v-model="feedbackReq.reason"
                  placeholder="请选择问题类型"
                >
                  <el-option label="Bug" value="1"></el-option>
                  <el-option label="建议" value="0"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="问题节点" prop="part">
                <el-select v-model="feedbackReq.part" placeholder="请选择节点">
                  <el-option label="AlphaIDE" value="3"></el-option>
                  <el-option label="LakeSoul" value="2"></el-option>
                  <el-option label="Metaspore" value="1"></el-option>
                  <el-option label="其他" value="0"></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="问题描述" prop="content">
                <el-input
                  type="textarea"
                  :autosize="{ minRows: 6, maxRows: 12 }"
                  v-model="feedbackReq.content"
                  placeholder="请您填写问题描述以便我们提供更好的服务（最多可输入200字）"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="联系方式" prop="contact">
                <el-input
                  v-model="feedbackReq.contact"
                  placeholder="请输入您的联系方式（邮箱或手机号）"
                  autocomplete="off"
                ></el-input>
              </el-form-item>

              <el-form-item label="问题截图" prop="img">
                <el-upload
                  class="upload-demo"
                  :action="uploadImgUrl"
                  ref="uploadimg"
                  :on-preview="handlePreview"
                  :on-remove="handleRemove"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :file-list="fileList"
                  :on-change="handleChange"
                  list-type="picture-card"
                  :auto-upload="false"
                  :headers="{ 'X-XSRF-TOKEN': csrfToken }"
                  :before-upload="beforeUpload"
                >
                  <el-button size="small" type="primary">点击上传</el-button>
                  <div slot="tip" class="el-upload__tip">
                    如有需要，请上传不超过3张小于1MB的jpg/png文件
                  </div>
                </el-upload>
              </el-form-item>

              <el-form-item>
                <el-button
                  type="primary"
                  class="submit_btn"
                  @click="submitForm('feedback')"
                  ><p class="alert">提交</p></el-button
                >
              </el-form-item>
            </el-form>
          </span>
        </div>
      </el-scrollbar>
    </section>
  </div>
</template>

<script>
const rules = require("../assets/userFormObject").FEEDBACK_RULES;
import { v4 as uuidv4 } from "uuid";
export default {
  name: "Login",
  data() {
    // var validatePass =(rule,value,callback)=>{
    //     let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,}$/;
    //     if(!value.match(regex)){
    //         callback(new Error('password should include at least One letter and One number'))
    //     }else{
    //         callback()
    //     }
    // }
    return {
      csrfToken: this.getcookie("XSRF-TOKEN"),
      feedbackReq: {
        feedbackId: "",
        title: "",
        content: "",
        contact: "",
        username: "",
        status: 1,
        reason: "",
        part: "",
      },
      formData: new FormData(),
      rules,
      fileList: [],
      uploadImgUrl: "",
      messageTimeSuccess: 0,
      messageTimeFailed: 0,
      typeSet: new Set(["image/jpeg", "image/jpg", "image/png"]),
    };
  },
  created() {
    this.feedbackReq.username = window.location.search.substring(10);
    if (!this.feedbackReq.username || this.feedbackReq.username === "null") {
      this.$router.push("/");
    }
    this.feedbackReq.feedbackId = this.feedbackReq.username + "-" + uuidv4();
    this.uploadImgUrl =
      "/api/api/feedbackImgUpload?feedbackId=" + this.feedbackReq.feedbackId;
  },
  mounted() {},
  methods: {
    handleRemove(file, fileList) {},
    handlePreview(file) {},
    handleChange(file, fileList) {
      const isJPG = this.typeSet.has(file.raw.type);
      const isLt2M = file.size / 1024 / 1024 < 1;
      if (!isJPG || !isLt2M) {
        !isJPG && this.$message.error("上传图片只能是 JPG/PNG 格式!");
        !isLt2M && this.$message.error("上传图片只能是 JPG/PNG 格式!");
        if (0 < this.fileList.length < 3) {
          this.fileList = fileList.slice(0, fileList.length - 1);
        }
      } else {
        this.fileList = fileList.slice(-3);
        console.log(file.raw.type);
      }
      // if (!isLt2M) {
      //   this.$message.error("上传图片大小不能超过 1MB!");
      //   this.fileList.pop();
      // }
      return isJPG && isLt2M;
    },
    beforeUpload(file) {},
    uploadSuccess(res) {
      if (this.messageTimeSuccess === 0) {
        this.$message({
          message: "感谢您的反馈，AlphaIDE团队会及时跟进。",
          type: "success",
        });
        this.messageTimeSuccess = this.messageTimeSuccess + 1;
        this.$router.push("/feedbackSuccess");
      }
    },
    uploadError(err) {
      if (this.messageTimeFailed === 0) {
        this.$message({
          message:
            "提交失败，如情况紧急，请联系AlphaIDE团队：alpha-ide@dmetasoul.com",
          type: "error",
        });
        this.messageTimeFailed = this.messageTimeFailed + 1;
        this.$router.go(0);
      }
    },
    submitForm(formname) {
      this.messageTimeFailed = 0;
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.formData.append("body", this.feedbackReq);
          if (this.fileList.length > 0) {
            //上传图片                 //若没有图片则往下走逻辑
            this.$refs.uploadimg.submit();
          }
          this.$axios({
            method: "post",
            url: "/api/feedbackUpload",
            data: this.feedbackReq,
          })
            .then((res) => {
              if (res.data.code != 200) {
                let message = res.data.status
                  ? res.data.status
                  : "系统错误，请联系管理员。";
                this.$message({
                  message: message,
                  type: "error",
                });
                // this.$router.go(0)
                return false;
              } else {
                //处理成功与失败逻辑回调在钩子函数uploadSuccess/uploadError中
                this.$message({
                  message: "感谢您的反馈，AlphaIDE团队会及时跟进。",
                  type: "success",
                });
                this.$router.push("/feedbackSuccess");
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },
    isEmpty(value) {
      return (
        value == undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length === 0) ||
        (typeof value == "String" && value.trim().length === 0)
      );
    },
    getcookie: function (objname) {
      //获取指定名称的cookie的值
      var arrstr = document.cookie.split("; ");
      for (var i = 0; i < arrstr.length; i++) {
        var temp = arrstr[i].split("=");
        if (temp[0] == objname) return unescape(temp[1]);
      }
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  width: 670px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  /*设定这个div的margin-top的负值为⾃⾝的⾼度的⼀半,margin-left的值也是⾃⾝的宽度的⼀半的负值.*/
  /*宽为400,那么margin-top为-200px*/
  /*⾼为200那么margin-left为-100px;*/
  margin: -200px 0 0 -335px;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}
.feedback {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 40px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #cccc;
}

.submit_btn {
  width: 100%;
}
.tiparea {
  margin: 10px;
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>