module.exports = {
    LOGIN_RULES: {
        username: [
            {
                // required: true,
                // message: "用户名不得为空。",
                // trigger: "blur",
                required: true,
                type: "email", //验证类型
                message: "邮箱格式不合法!",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
            // {
            //     min: 2,
            //     max: 30,
            //     message: "用户名长度为2至30。",
            //     trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            // },
        ],
        password: [
            {
                required: true,
                message: "密码不得为空。",
                trigger: "blur",
            },
            {
                min: 6,
                max: 30,
                message: "密码长度为6至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
            // {
            //   validator: validatePass,
            //   trigger: "blur",
            // },
        ],
    },
    REGISTER_RULES: {
        username: [
            {
                required: true,
                message: "用户名不得为空。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
            {
                min: 2,
                max: 30,
                message: "用户名长度为2至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        email: [
            {
                required: true,
                type: "email", //验证类型
                message: "邮箱格式不合法!",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        password: [
            {
                required: true,
                message: "密码不得为空",
                trigger: "blur",
            },
            {
                min: 6,
                max: 30,
                message: "密码长度为6至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
            // {
            //     validator: validatePass,
            //     trigger: "blur",
            // },
        ],
        password2: [
            {
                required: true,
                message: "密码确认不得为空",
                trigger: "blur",
            },
            {
                min: 6,
                max: 30,
                message: "密码确认长度为6至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
            // {
            //     validator: validatePass2,
            //     tigger: "blur",
            // },
        ],
        rightLevel: [
            {
                required: true,
                message: "请选择至少一个权限角色",
                trigger: "blur",
            },
        ],
        company: [
            {
                required: true,
                message: "公司名不得为空。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
    },
    RESET_PASSWORD_RULES: {
        username: [
            {
                required: true,
                type: "email", //验证类型
                message: "邮箱格式不合法!",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        code: [
            {
                required: true,
                message: "验证码不得为空",
                trigger: "blur",
            },
            {
                min: 6,
                max: 6,
                message: "验证码长度应为6。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        password: [
            {
                required: true,
                message: "密码不得为空",
                trigger: "blur",
            },
            {
                min: 6,
                max: 30,
                message: "密码长度为6至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        password2: [
            {
                required: true,
                message: "密码确认不得为空",
                trigger: "blur",
            },
            {
                min: 6,
                max: 30,
                message: "密码确认长度为6至30。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ]
    },
    FEEDBACK_RULES: {
        title: [
            {
                required: true,
                message: "请输入标题",
                trigger: "blur",
            },
            {
                min: 5,
                max: 50,
                message: "标题长度5-50个字",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        reason: [
            {
                required: true,
                message: "请选择问题类型",
                trigger: "blur",
            },
        ],
        part: [
            {
                required: true,
                message: "请选择问题节点",
                trigger: "blur",
            },
        ],
        content: [
            {
                required: true,
                message: "请输入问题描述",
                trigger: "blur",
            },
            {
                min: 0,
                max: 200,
                message: "问题描述最长200个字。",
                trigger: "blur", //失去焦点时（输入完点到下面到输入框时）触发检查
            },
        ],
        contact: [
            {
                required: true,
                message: "请输入联系方式",
                trigger: "blur",
            },
            { pattern: /(^([A-Za-z0-9_\-\.\u4e00-\u9fa5])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,8})$)|(^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$)/gm, message: '格式有误，请正确输入邮箱或手机号码' }
        ],
    }
}
