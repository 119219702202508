<template>
  <div class="login">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title">
          <div v-if="!!success">
            <el-result icon="success" title="您的账号已激活！">
              <template #extra>
                <p class="enterMsg">{{!!success?count:""}}{{ enterMsg }}</p>
                <el-button type="primary" @click="back" class="enterSys"
                  >进入管理页面</el-button
                >
              </template>
            </el-result>
          </div>
          <div v-else>
            <el-result icon="error" title="账号激活失败，请重试！">
              <template #extra>
                <p class="enterMsg">{{ enterMsg }}</p>
              </template>
            </el-result>
          </div>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "EmailSent",
  data() {
    return {
      enterMsg: "",
      count: 5,
      success: false,
    };
  },
  created() {
    console.log(JSON.stringify(document));
    if (document.location.search.indexOf("token")!==-1) {
      this.success = true;
      this.enterMsg = "秒后自动跳转至管理系统";
      let token = decodeURIComponent(
        document.location.search.match(/\?token=(\S*)/)[1]
      );
      let jumpTimer = setInterval(() => {
        if (this.count < 1) {
          clearInterval(jumpTimer);
          this.$router.push("/index");
        } else {
          this.count--;
        }
      }, 1000);
    } else {
      this.enterMsg = decodeURIComponent(
        document.location.search.match(/\?errorMsg=(\S*)/)[1]
      );
    }
  },
  methods: {
    back() {
      !!this.success?this.$router.push("/index"):this.$router.push("/register")
    },
  },
};
</script>

<style scoped>
.enterMsg {
  font-size: 10px;
}
.enterSys {
  margin: 15px;
}
.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}

.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>