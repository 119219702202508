<template>
  <div class="notfound">
      <img src="../assets/404.gif" alt="">
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
    .notfound{
        width: 100%;
        height: 100%;
        overflow: hidden;
    }
    .notfound img{
        width: 100%;
        height: 100%;
    }
</style>