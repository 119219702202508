//定义常量，使用常量替代 mutation 事件类型
// 定义类型常量，默认全部大写
const INCREMENT = 'INCREMENT'
const DECREMENT = 'DECREMENT'
const UPDATECOUNT = 'UPDATECOUNT'//更新总数
const PAGECHANGE = 'PAGECHANGE'//改变页数
const UPDATEFEEDBACK = 'UPDATEFEEDBACK'//更新列表（置顶，改变状态）

export default {
    INCREMENT,
    DECREMENT,
    UPDATECOUNT,
    PAGECHANGE,
    UPDATEFEEDBACK,
}