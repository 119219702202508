<template>
  <div class="sideNav">
    <el-row class="tac">
      <!-- <el-col :span="18"> -->
      <el-menu :default-active="2" class="el-menu-vertical-demo">
        <el-menu-item disabled @click="this.$router.push('/form/channel')" index="1">
          <el-icon>
            <Plus />
          </el-icon>
          <span>创建集群</span>
        </el-menu-item>
        <el-menu-item @click="this.$router.push('/clusterList')" index="2">
          <el-icon>
            <Suitcase />
          </el-icon>
          <span>我的集群</span>
        </el-menu-item>
        <el-menu-item @click="this.$router.push('/about')" index="3">
          <el-icon>
            <User />
          </el-icon>
          <span>个人信息</span>
        </el-menu-item>
        <el-menu-item v-if="this.rightLevel >= 2" @click="this.$router.push('/problem')" index="4">
          <el-icon>
            <Reading />
          </el-icon>
          <span>问题反馈</span>
        </el-menu-item>
      </el-menu>
      <!-- </el-col> -->
    </el-row>
  </div>
</template>

<script>
import decode from "jwt-decode";
export default {
  data() {
    return {
      rightLevel: 0
    }
  },
  created() {
    this.rightLevel = decode(localStorage.getItem("eleToken")).rightLevel
  }
};
</script>

<style>
.sideNav {
  display: block;
  position: relative;
  left: 0;
  width: 100%;
  bottom: 0;
  top: 0;
}

.el-menu-vertical-demo {
  width: 100%;
}
</style>