<template>
  <div class="login">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title">
          <el-result
            icon="success"
            title="激活邮件已发送,请进入您的注册邮箱查收，点击链接并激活账号; 激活链接仅当次有效，请尽快激活。"
          >
            <template #extra>
              <el-button type="primary" @click="back">返回</el-button>
            </template>
          </el-result>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "EmailSent",
  data() {
    return {
      
    };
  },
  methods: {
    back() {
      this.$router.push("/login");
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: #fff;
}

.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>