<template>
  <div id="app">
    <router-view />
    <Loading v-show="loading" />
  </div>
</template>
<script>
import TopNav from "@/components/Common/TopNav.vue";
import SideNav from "./components/Common/SideNav.vue";
import Loading from "./components/Common/Loading.vue";
export default {
  components: {
    Loading,
  },
  computed: {
    loading() {
      console.log("loading");
      return this.$store.getters.loading;
    },
  },
};
</script>
<style lang="less">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "黑体";
  font-size: 15px;
}
.tips{
  font-size: 10px;
}
.alert{
  font-size: 15px;
}
.icon {
  width: 15px;
  height: 15px;
}
.bottom {
  position: absolute;
  width: 100%;
  top: 100px;
  left: 0;
  bottom: 0;
  background: #eef0f5;
}
</style>
