// 导入 types.js 文件
import types from "../types";
import axios from "axios";


const state = {
    feedback: [],
    size: 3,
    page: 1,
    totalCount: 0,
    sihft: {
        value0: [],
        value1: [],
        value2: [],
    },
    status: '',
    reason: '',
    part: '',
}
const mutations = {
    [types.UPDATECOUNT](state, res) {
        state.totalCount = res.feedbackSize
    },
    [types.UPDATEFEEDBACK](state, res) {//更新数据（）
        state.feedback = []
        // state.totalCount = res.rltObj.feedbacskTotalSize
        state.totalCount = res.rltObj.feedbackTotalSize * (state.size / (state.size + 1))//因为size每次多返回一条   
        res.rltObj.feedbackList.forEach((item, index) => {
            state.feedback.push(item)
        });
        state.feedback.forEach((item, index) => {
            if (item.feedbackStatus === 0) {//确认是否置顶
                item.isUp = true
            }
            else {
                item.isUp = false
            }
            item.radio = item.feedbackStatus == 2 ? "已解决" : "未解决"//初始化状态

        })
    },
    changeTopIcon(state, item) {
        if (!item.isUp) {
            item.isUp = true
        } else {
            item.isUp = !item.isUp
        }
    },

}
// 定义 getters
var getters = {
    status(state) {
        return state.sihft.value0.join('')
    },
    reason(state) {
        return state.sihft.value1.join('')
    },
    part(state) {
        return state.sihft.value2.join('')
    }
}

const actions = {
    getTotalCount({ commit, state }) {
        axios({
            method: "get",
            url: "/api/feedbackCount",
        }).then((res) => {
            commit(types.UPDATECOUNT, res.data)
        }).catch(() => {
            console.log("获取总数失败")
        })

    },
    async getFeedback({ getters, dispatch, commit, state }, payload) {
        // await dispatch('getTotalCount')
        if (payload == "filter") {
            state.page = 1
        }
        await axios({
            method: "get",
            // url: `/api/feedbackByIndex?start=${(state.page - 1) * state.size}&end=${state.page * state.size}&status=${getters.status}&reason=${getters.reason}&part=${getters.part}`,
            url: `/api/feedbackByIndex?start=${(state.page - 1) * (state.size + 1)}&end=${state.size + ((state.page - 1) * (state.size + 1))}&status=${getters.status}&reason=${getters.reason}&part=${getters.part}`,//star,end等差数列（0-3，4-7，8-11）
        }).then((res) => {
            commit(types.UPDATEFEEDBACK, res.data)
        }).catch(() => {
            console.log("获取列表失败")
        })
    },
    getImage({ commit, state }, item) {
        axios({
            method: "post",
            url: "/api/feedbackFind",
            data: { 'feedbackID': item.feedbackId },
        }).then((res) => {
            item.feedbackImgList = res.data.rltObj.feedbackImgList
        }).catch(() => {
            console.log("获取图片失败")
        })
    },
    async changeStatus({ dispatch, commit, state }, payload) {
        await axios({
            method: "post",
            url: "/api/feedbackUpdate",
            data: { 'feedbackId': payload.item.feedbackId, 'status': payload.statusItem.id },
        }).then((res) => {
            dispatch('getFeedback')
        }).catch(() => {
            console.log("修改状态失败")
        })
    },
    async toTop({ dispatch, commit, state }, item) {//置顶
        await axios({
            method: "post",
            url: "/api/feedbackUpdate",
            data: { 'feedbackId': item.feedbackId, 'status': 0 },
        }).then((res) => {
            dispatch('getFeedback')
        }).catch(() => {
            console.log("置顶失败")
        })
    },
    async toBottom({ dispatch, commit, state }, item) {
        await axios({
            method: "post",
            url: "/api/feedbackUpdate",
            data: { 'feedbackId': item.feedbackId, 'status': 2 },
        }).then((res) => {
            dispatch('getFeedback')
        }).catch(() => {
            console.log("取消置顶失败")
        })
    },
    async deleteHandle({ getters, dispatch, commit, state }, item) {//删除
        axios({
            method: "post",
            url: "/api/feedbackDelete",
            data: { 'feedbackID': item.feedbackId },
        }).then((res) => {
            dispatch('getFeedback')
        }).catch(() => {
            console.log("删除失败")
        })
    },
    pageChange({ dispatch, commit, state }, page) {
        state.page = page
        dispatch('getFeedback')
    },
}
// 最后统一导出
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}