import axios from "axios";
// import store from "../store/index";
import { ElLoading , ElMessage } from 'element-plus';
import router from '../router/index'
import store from "../store/index";
import decode from "jwt-decode";

//请求拦截
axios.interceptors.request.use(config => {
    // startLoading()
    store.dispatch('setLoading', true)
    //token持久化
    if (localStorage.eleToken) {
        let user = decode(localStorage.eleToken)
        store.dispatch("setAuthenticate", true);
        store.dispatch("setUser", user);
        config.headers.Authorization = localStorage.eleToken
    }
    return config
}, error => {
    store.dispatch('setLoading', false)
    return Promise.reject(error)
})

//响应拦截
axios.interceptors.response.use(response => {
    const { code } = response.data
    //判断是否状态吗401（登录状态异常，包括token失效情况拦截）
    if (code == 410) {
        ElMessage.error(response.data.status)
        //清除token
        localStorage.removeItem('eleToken')
        router.push('/login')
        store.dispatch('setLoading', false)
        return Promise.reject(response.data.status)
    } else {
        store.dispatch('setLoading', false)
        return response
    }
}, error => {
    //目前有访问错误直接登出，最大限度保护业务逻辑
    localStorage.removeItem('eleToken')
    ElMessage.error(error.message)
    router.push('/login')
    store.dispatch('setLoading', false)
    return Promise.reject(error)
})


export default axios;