<template>
  <div class="login">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title"
          >欢迎使用 Alpha IDE 服务
          <el-form
            :model="loginUser"
            status-icon
            :rules="rules"
            ref="loginForm"
            label-width="100px"
            class="loginForm"
          >
            <el-form-item label="邮箱" prop="username">
              <el-input
                v-model="loginUser.username"
                placeholder="请输入邮箱"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="loginUser.password"
                placeholder="请输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                class="submit_btn"
                @click="submitForm('loginForm')"
                ><p class="alert">登录</p></el-button
              >
            </el-form-item>
            <div class="tiparea">
              <router-link to="/resetPassword"
                ><p class="alert">忘记密码?</p></router-link
              >
            </div>
            <div class="tiparea">
              <p class="alert">
                还没有账号?<router-link to="/register"
                  ><p class="alert">注册</p></router-link
                >
              </p>
            </div>
          </el-form>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import decode from "jwt-decode";
import qs from "qs";
const rules = require("../assets/userFormObject").LOGIN_RULES;
export default {
  name: "Login",
  data() {
    // var validatePass =(rule,value,callback)=>{
    //     let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,}$/;
    //     if(!value.match(regex)){
    //         callback(new Error('password should include at least One letter and One number'))
    //     }else{
    //         callback()
    //     }
    // }
    return {
      loginUser: {
        username: "",
        password: "",
      },
      rules,
    };
  },
  methods: {
    submitForm(formname) {
      this.$refs[formname].validate((valid) => {
        if (valid) {
          this.loginUser.password = this.$getRsaCode(this.loginUser.password);
          this.$axios({
            method: "post",
            url: "/api/user/doLogin",
            data: qs.stringify(this.loginUser), //username=zxx&&password=xxx的格式
            headers: {
              //此处迁就了后端的spring security取参的方式临时修改，默认为application/json
              "Content-Type": "application/x-www-form-urlencoded",
            },
          })
            .then((res) => {
              if (res.data.code != 200) {
                let message = res.data.status
                  ? res.data.status
                  : "系统错误，请联系管理员。";
                this.$message({
                  message: message,
                  type: "error",
                });
                // this.registerUser.password = passwordOrigin;
                // this.registerUser.password2 = passwordOrigin;
                this.loginUser.password = "";
                this.$router.push("/login");
                return false;
              }
              this.$message({
                message: "登录成功",
                type: "success",
              });
              //token
              let token = res.data.token;
              localStorage.setItem("eleToken", token);

              const user = decode(token);
              this.$store.dispatch("setAuthenticate", !this.isEmpty(user));
              this.$store.dispatch("setUser", user);

              this.$router.push("/");
            })
            .catch((error) => {
              //输入框置空 防止加密后密码留在输入框
              this.loginUser.password = "";
              console.log(error);
            });
        }
      });
    },
    isEmpty(value) {
      return (
        value == undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length === 0) ||
        (typeof value == "String" && value.trim().length === 0)
      );
    },
  },
};
</script>

<style scoped>
.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  width: 470px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  /*设定这个div的margin-top的负值为⾃⾝的⾼度的⼀半,margin-left的值也是⾃⾝的宽度的⼀半的负值.*/
  /*宽为400,那么margin-top为-200px*/
  /*⾼为200那么margin-left为-100px;*/
  margin: -200px 0 0 -235px;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}
.loginForm {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 40px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 10px #cccc;
}

.submit_btn {
  width: 100%;
}
.tiparea {
  margin: 10px;
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>