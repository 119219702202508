<template>
  <div class="register">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title"
          >注册账户
          <el-form
            :model="registerUser"
            status-icon
            :rules="rules"
            ref="registerForm"
            label-width="100px"
            class="registerForm"
          >
            <!-- <el-form-item label="用户名" prop="username">
              <el-input
                v-model="registerUser.username"
                placeholder="请输入用户名"
                autocomplete="off"
              ></el-input>
            </el-form-item> -->

            <el-form-item label="邮箱" prop="email">
              <el-input
                v-model="registerUser.email"
                placeholder="请输入邮箱"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="密码" prop="password">
              <el-input
                type="password"
                v-model="registerUser.password"
                placeholder="请输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="密码确认" prop="password2">
              <el-input
                type="password"
                v-model="registerUser.password2"
                placeholder="请再次输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="公司名称" prop="company">
              <el-input
                v-model="registerUser.company"
                placeholder="请输入公司名"
                autocomplete="off"
              ></el-input>
            </el-form-item>
            <el-form-item label="权限" prop="rightLevel">
              <el-select
                v-model="registerUser.rightLevel"
                placeholder="请选择权限"
              >
                <el-option label="企业管理者" value="0"> </el-option>
                <el-option label="企业员工" value="1"> </el-option>
              </el-select>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                class="submit_btn"
                @click="submitForm('registerForm')"
                ><p class="alert">提交</p></el-button
              >
            </el-form-item>
            <el-form-item>
              <el-button @click="readMe" class="readMe"
                >AlphaIDE 服务使用引导</el-button
              >
            </el-form-item>
            <div class="tiparea">
              <p class="alert">
                已有账户?<router-link to="/login"
                  ><p class="alert">登录</p></router-link
                >
              </p>
            </div>
          </el-form>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import decode from "jwt-decode";
const rules = require("../assets/userFormObject").REGISTER_RULES;
export default {
  name: "Register",
  commponents: {},
  data() {
    // var validatePass2 = (rule, value, callback) => {
    //   if (value !== this.registerUser.password) {
    //     console.log("确认验证", this.registerUser.password2);
    //     callback(new Error("两次密码输入不同"));
    //   } else {
    //     callback();
    //   }
    // };
    // var validatePass = (rule, value, callback) => {
    //   let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,}$/;
    //   console.log("验证", this.registerUser.password);
    //   if (!this.registerUser.password.match(regex)) {
    //     callback(new Error("密码至少含有一个数字及一个字母"));
    //   } else {
    //     callback();
    //   }
    // };
    // rules.password.push({
    //   validator: validatePass,
    //   trigger: "blur",
    // });
    // rules.password2.push({
    //   validator: validatePass2,
    //   trigger: "blur",
    // });
    return {
      registerUser: {
        username: "",
        email: "",
        avatar: "www://123.com",
        password: "",
        password2: "",
        rightLevel: "",
        company: "",
      },
      rules,
    };
  },
  created() {},
  methods: {
    readMe() {
      window.open(
        "https://dmetasoul.feishu.cn/docs/doccnow35xx6GrQsGXxmm9bFkCc"
      );
    },
    submitForm(formname) {
      if (this.registerUser.password !== this.registerUser.password2) {
        this.$message.error("两次密码输入不一致，请重新输入！");
        return;
      }
      //ref="registerForm"
      let passwordOrigin;
      //目前username与邮箱保持一致
      this.registerUser.username = this.registerUser.email;
      //rightlevel从string转为number
      this.registerUser.rightLevel = Number.parseInt(
        this.registerUser.rightLevel
      );
      this.$refs[formname].validate((valid) => {
        if (valid) {
          // try {
          passwordOrigin = this.registerUser.password;
          this.registerUser.password = this.$getRsaCode(
            this.registerUser.password
          );
          this.registerUser.password2 = this.registerUser.password;
          console.log("请求：" + JSON.stringify(this.registerUser));
          this.$axios({
            url: "/api/user/signUp",
            data: this.registerUser,
            method: "post",
          })
            //有一点需要注意的是，axios中POST的默认请求体类型为Content-Type:application/json（JSON规范流行），
            //这也是最常见的请求体类型，也就是说使用的是序列化后的json格式字符串来传递参数，
            //如：{ “name” : “mike”, “sex” : “male” }；同时，后台必须要以支持@RequestBody的形式接收参数，否则会出现前台传参正确，后台接收不到的情况。
            .then((res) => {
              console.log(JSON.stringify(res));
              if (res.data.code != 200) {
                let message = res.data.status
                  ? res.data.status
                  : "系统错误，请联系管理员。";
                this.$message({
                  message: message,
                  type: "error",
                });
                // this.registerUser.password = passwordOrigin;
                // this.registerUser.password2 = passwordOrigin;
                this.registerUser.password = "";
                this.registerUser.password2 = "";
                this.registerUser.rightLevel = "";
                this.$router.push("/register");
                return false;
              }
              this.$message({
                //element的message方法 前端弹窗显示
                message: "激活邮件已发送",
                type: "success",
              });
              //token 注册成功自动登录用，这里暂时不用，用户点击激活链接后的逻辑。
              // let token = res.data.token;
              // localStorage.setItem("eleToken", token);

              // const user = decode(token);
              // this.$store.dispatch("setAuthenticate", !this.isEmpty(user));
              // this.$store.dispatch("setUser", user);
              this.$router.push("/emailSent");
              return true;
            })
            .catch((err) => {
              let message = err.message
                ? err.message
                : "系统错误，请联系管理员。";
              this.$message({
                message: message,
                type: "error",
              });
              // this.registerUser.password = passwordOrigin;
              // this.registerUser.password2 = passwordOrigin;
              this.registerUser.password = "";
              this.registerUser.password2 = "";
              this.registerUser.rightLevel = "";
              console.log(JSON.stringify(this.registerUser));
              this.$router.push("/register");
              return false;
            });
          // this.$router.push("/index");
        } else {
          console.log(this.registerUser);
          console.log("错误的提交");
          return false;
        }
      });
    },
    isEmpty(value) {
      return (
        value == undefined ||
        value === null ||
        (typeof value == "object" && Object.keys(value).length === 0) ||
        (typeof value == "String" && value.trim().length === 0)
      );
    },
  },
};
</script>

<style scoped>
.register {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  width: 470px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  /*设定这个div的margin-top的负值为⾃⾝的⾼度的⼀半,margin-left的值也是⾃⾝的宽度的⼀半的负值.*/
  /*宽为400,那么margin-top为-200px*/
  /*⾼为200那么margin-left为-100px;*/
  margin: -200px 0 0 -235px;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}
.registerForm {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #cccc;
}
.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>