import { createStore } from 'vuex'
import { v4 as uuidv4 } from 'uuid';
import feedBackProblemModules from './Modules/feedBackProblemModules'

export default createStore({
  state: {
    user: {},
    reqData: {
      nodesOptions: [],
      appCommonConfig: {},
      appCommonConfigObj: {},
      appCustomConfig: {},
      userId: "",
      clusterPrimaryKey: ""
    },
    processActive: 0,
    loading: false,
    buildingStatus: false,
    cloudBrand: '',
    clusterPrimaryKey: '',
    unsavedChanges: {},
    statusPageInterval: null,
    intervalFlag: false,
  },
  mutations: {
    SET_USERID_INTO_REQ(state) {
      state.reqData.userId = state.user.userId
    },
    SET_CLUSTERPRIMARYKEY_INTO_REQ(state) {
      state.reqData.clusterPrimaryKey = uuidv4()
    },
    SET_AUTHENTICATED(state, isAuthenticated) {
      if (isAuthenticated) state.isAuthenticated = isAuthenticated
      else state.isAuthenticated = false
    },
    SET_USER(state, user) {
      if (user) state.user = user
      else user = {}
    },
    LOG_OUT(state) {
      localStorage.removeItem('eleToken')
      state.isAuthenticated = false
      state.user = {}
    },
    setDriverOptions(state, data) {
      state.reqData.driverOptions = data
    },
    setNodesOptions(state, data) {
      state.reqData.nodesOptions = data
    },
    setAppCommonConfig(state, data) {
      state.reqData.appCommonConfig = data
    },
    deleteNodesOptions(state, index) {
      if (index !== -1) state.reqData.nodesOptions.splice(index, 1)
    },
    updateNodesOptions(state, data, index) {
      if (index !== -1) state.reqData.nodesOptions[index] = data
    },
    setAccessData(state, data) {
      for (let key in data) {
        state.reqData[key] = data[key]
      }
    },
    setCloudChannel(state, cloud) {
      this.resetReqData(state)
      state.reqData.cloud = cloud
    },
    setProcessActive(state, numActive) {
      state.processActive = numActive
    },
    resetReqData(state) {
      state.reqData = {
        nodesOptions: [],
        appCommonConfig: {},
        appCustomConfig: {},
      }
    },
    setLoading(state, flag) {
      state.loading = flag
    },
    setBuildingStatus(state, flag) {
      state.buildingStatus = flag
    },
    setProcessActive(state, val) {
      state.processActive = val
    },
    setCloudBrand(state, brand) {
      state.cloudBrand = brand
    },
    setClusterPrimaryKey(state, clusterPrimaryKey) {
      state.clusterPrimaryKey = clusterPrimaryKey
    },
    setUnsavedChanges(state, arr) {
      // console.log(arr[0]);
      // console.log(arr[1]);
      state.unsavedChanges[arr[0]] = arr[1]
    },
    deleteUnsavedChangesProp(state, key) {
      delete state.unsavedChanges[key];
    },
    setStatusPageInterval(state, interval) {
      state.statusPageInterval = interval;
    },
    setIntervalFlag(state, data) {
      state.intervalFlag = data
    },
    setIntervalFlag(state, flag) {
      state.intervalFlag = flag
    },
    setAppCustomConfig(state, compsConfig) {
      state.reqData.appCustomConfig = compsConfig;
    },
    setAppCommonConfigToObj(state) {
      console.log("进入");
      state.reqData.appCommonConfigObj = JSON.parse(JSON.stringify(state.reqData.appCommonConfig))
      state.reqData.appCommonConfig = {}
      console.log("退出：" + state.reqData.appCommonConfigObj);
    },
    setObjToAppCommonConfig(state) {
      state.reqData.appCommonConfig = JSON.parse(JSON.stringify(state.reqData.appCommonConfigObj))
      state.reqData.appCommonConfigObj = {}
    },
    clearReqData(state) {
      state.reqData = {
        nodesOptions: [],
        appCommonConfig: {},
        appCommonConfigObj: {},
        appCustomConfig: {},
      }
    }
  },
  actions: {
    setUserIdIntoReq({ commit }, data) {
      commit('SET_USERID_INTO_REQ', data)
    },
    setClusterPrimaryKeyIntoReq({ commit }) {
      commit('SET_CLUSTERPRIMARYKEY_INTO_REQ')
    },
    setAuthenticate({ commit }, isAuthenticated) { commit('SET_AUTHENTICATED', isAuthenticated) },
    setUser({ commit }, user) { commit('SET_USER', user) },
    logOut({ commit }) { commit('LOG_OUT') },

    setDriverOptions({ commit }, data) {
      commit('setDriverOptions', data)
    },
    setNodesOptions({ commit }, data) {
      commit('setNodesOptions', data)
    },
    setAppCommonConfig({ commit }, data) {
      commit('setAppCommonConfig', data)
    },
    deleteNodesOptions({ commit }, data) {
      commit('deleteNodesOptions', data)
    },
    setAccessData({ commit }, data) {
      commit('setAccessData', data)
    },
    setCloudChannel({ commit }, data) {
      commit('setCloudChannel', data)
    },
    setProcessActive({ commit }, numActive) {
      commit('setProcessActive', numActive)
    },
    resetReqData({ commit }) {
      commit('resetReqData')
    },
    setLoading({ commit }, flag) {
      commit('setLoading', flag)
    },
    setBuildingStatus({ commit }, flag) {
      commit('setBuildingStatus', flag)
    },
    setIntervalFlag({ commit }, flag) {
      commit('setIntervalFlag', flag)
    },
    setAppCommonConfigToObj({ commit }) {
      commit('setAppCommonConfigToObj')
    },
    setObjToAppCommonConfig({ commit }) {
      commit('setObjToAppCommonConfig')
    }
  },
  modules: {
    feedBackProblemModules
  },
  getters: {
    reqData: (state) => state.reqData,
    processActive: (state) => state.processActive,
    loading: (state) => state.loading,
    buildingStatus: (state) => state.buildingStatus,
    cloudBrand: (state) => state.cloudBrand,
    clusterId: (state) => state.clusterId,
    unsavedChanges: (state) => state.unsavedChanges,
    statusPageInterval: (state) => state.statusPageInterval,
    intervalFlag: (state) => state.intervalFlag,
    clusterPrimaryKey: (state) => state.clusterPrimaryKey,
    user: (state) => state.user
  }
})
