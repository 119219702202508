<template>
  <div id="d1">
    <el-affix offset="0">
      <div class="topNav">
        <div>
          <router-link to="/"
            ><img class="logo" src="../../assets/logo.png" alt=""
          /></router-link>
        </div>

        <div class="topCenter">
          <el-menu
            :default-active="activeIndex"
            class="top-nav-menu"
            mode="horizontal"
            @select="handleSelect"
          >
            <el-menu-item index="1" class="top-nav-item">产品</el-menu-item>

            <el-menu-item index="2" class="top-nav-item">开源</el-menu-item>
            <el-menu-item index="3" class="top-nav-item">学习</el-menu-item>
            <el-menu-item index="4" class="top-nav-item">博客</el-menu-item>
            <el-sub-menu index="5">
              <template #title>我的</template>
              <el-menu-item index="5-1" @click="this.$router.push('/about')"
                >个人中心</el-menu-item
              >
              <el-menu-item index="5-2" @click="logout"
                >退出登录</el-menu-item
              ></el-sub-menu
            >
            <el-menu-item index="6" class="top-nav-item">关于</el-menu-item>
          </el-menu>
        </div>
      </div>
    </el-affix>
  </div>
</template>

<script>
export default {
  name: "TopNav",
  props: {},
  methods: {
    logout() {
      this.$store.dispatch("logOut");
      this.$message({
        message: "登出成功",
        type: "success",
      });
      this.$router.push("/login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">
@height: 100px;
@brandColor: #409eff;
@smallBigFontSize: 0.2rem;
@info: #909399;
@bigText: 0.12rem;
@fontFamily: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
  "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

.topNav {
  width: 100%;
  height: @height;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.2rem;
  color: black;
  background-color: white;
  // box-shadow:0px 0px 1px 0px @info;
  margin-bottom: 0.1rem;
}
.topCenter {
  justify-content: space-around;
  display: flex;
  color: rgb(146, 145, 145);
  font-size: @smallBigFontSize;
}
.logo {
  position: relative;
  max-height: @height - 15px;
  top: 0.02rem;
}

.top-nav-menu {
  border-bottom: none;
}

.top-nav-item {
  // font-size: @bigText;
  font-family: @fontFamily;
  color: @info;
}
</style>
