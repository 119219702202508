<template>
  <div class="login">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title">
          <el-result icon="success" title="您的问题反馈已提交！">
            <template #extra>
              <p class="enterMsg"></p>
            </template>
          </el-result>
        </span>
        <el-button @click="close()">关闭页面</el-button>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "FeedbackSuccess",
  data() {
    return {};
  },
  created() { },
  methods: {
    close() {
      if (
        navigator.userAgent.indexOf("Firefox") != -1 ||
        navigator.userAgent.indexOf("Chrome") != -1
      ) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        // window.open("", "_self");
        window.close();
      }
    },
  },
};
</script>

<style scoped>
.enterMsg {
  font-size: 10px;
}

.enterSys {
  margin: 15px;
}

.login {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}

.form_container {
  text-align: center;
}

.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}

.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
}

.tiparea p a {
  color: #409eff;
}
</style>