import { createApp } from 'vue'
import Vue from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from './utils/https'
import VueAxios from 'vue-axios'
import { Icon } from 'vant';
import "./utils/https.js"
import _ from 'lodash'
import JSEncrypt from 'jsencrypt';
import * as ElementPlusIconsVue from '@element-plus/icons-vue'



axios.defaults.baseURL = '/api'

// Vue.config.productionTip = false

axios.defaults.headers.post['Content-Type'] = 'application/json';
// Vue.prototype.$axios = axios

// Vue.prototype.$getRsaCode = function(str){ // 注册方法
//   let pubKey = require('./assets/secretConstant').publicKey;// ES6 模板字符串 引用 rsa 公钥
//   let encryptStr = new JSEncrypt();
//   encryptStr.setPublicKey(pubKey); // 设置 加密公钥
//   let  data = encryptStr.encrypt(str.toString());  // 进行加密
//   return data;
// }


const app = createApp(App)
app.config.productionTip = false
app.config.globalProperties.$axios = axios
app.config.globalProperties.$getRsaCode = function (str) { // 注册方法
  let pubKey = require('./assets/secretConstant').publicKey;// ES6 模板字符串 引用 rsa 公钥
  let encryptStr = new JSEncrypt();
  encryptStr.setPublicKey(pubKey); // 设置 加密公钥
  let data = encryptStr.encrypt(str.toString());  // 进行加密
  return data;
}
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {//icon
  app.component(key, component)
}
app.use(store).use(router).use(VueAxios, axios).use(Vant).use(Icon).use(ElementPlus).use(_).mount('#app')