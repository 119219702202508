<template>
  <div class="register">
    <section class="form_container">
      <div class="manage_tip">
        <span class="title"
          >密码重置
          <el-form
            :model="resetBody"
            status-icon
            :rules="rules"
            ref="registerForm"
            label-width="100px"
            class="registerForm"
          >
            <el-form-item label="邮箱" prop="username">
              <el-input
                v-model="resetBody.username"
                placeholder="请输入邮箱"
                autocomplete="off"
              ></el-input>
              <el-button
                type="primary"
                @click="sendCode"
                class="sendCode"
                :disabled="disable"
                :class="{ codeGeting: isGeting }"
                ><p style="font-size: 15px !important">
                  {{ getCode }}
                </p></el-button
              >
            </el-form-item>
            <el-form-item label="" prop="code">
              <el-input
                v-model="resetBody.code"
                placeholder="请输入收到的验证码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="新密码" prop="password">
              <el-input
                type="password"
                v-model="resetBody.password"
                placeholder="请输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item label="新密码确认" prop="password2">
              <el-input
                type="password"
                v-model="resetBody.password2"
                placeholder="请再次输入密码"
                autocomplete="off"
              ></el-input>
            </el-form-item>

            <el-form-item>
              <el-button
                type="primary"
                class="submit_btn"
                @click="submitForm('registerForm')"
                ><p class="alert">提交</p></el-button
              >
            </el-form-item>
            <div class="tiparea">
              <p class="alert">
                已有账户?<router-link to="/login">
                  <p class="alert">登录</p></router-link
                >
              </p>
            </div>
          </el-form>
        </span>
      </div>
    </section>
  </div>
</template>

<script>
import decode from "jwt-decode";
const rules = require("../assets/userFormObject").RESET_PASSWORD_RULES;
export default {
  name: "Register",
  commponents: {},
  data() {
    // var validatePass2 = (rule, value, callback) => {
    //   console.log(
    //     "确认验证",
    //     this.resetBody.password2 + "=====" + this.resetBody.password
    //   );
    //   if (value !== this.resetBody.password) {
    //     callback(new Error("两次密码输入不同"));
    //   } else {
    //     callback();
    //   }
    // };
    // var validatePass = (rule, value, callback) => {
    //   let regex = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{0,}$/;
    //   console.log("验证", this.resetBody.password);
    //   if (!this.resetBody.password.match(regex)) {
    //     callback(new Error("密码至少含有一个数字及一个字母"));
    //   } else {
    //     callback();
    //   }
    // };
    // rules.password2.push({
    //   validator: validatePass2,
    //   trigger: "change",
    // });
    // rules.password.push({
    //   validator: validatePass,
    //   trigger: "change",
    // });

    return {
      resetBody: {
        username: "",
        code: "",
        password: "",
        password2: "",
      },
      rules,
      getCode: "获取验证码",
      isGeting: false,
      count: 60,
      disable: false,
    };
  },
  created() {},
  methods: {
    sendCode() {
      if (!this.resetBody.username) {
        this.$message.error("请输入邮箱！");
        return;
      }
      this.$axios({
        method: "post",
        url: "/api/user/sendCode",
        data: {
          username: this.resetBody.username,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.$message({
              //element的message方法 前端弹窗显示
              message: "验证码已发送至邮箱",
              type: "success",
            });
            //60秒内不得重复发送验证码
            var countDown = setInterval(() => {
              if (this.count < 1) {
                this.isGeting = false;
                this.disable = false;
                this.getCode = "获取验证码";
                this.count = 60; //倒计时起始值重置
                clearInterval(countDown);
              } else {
                this.isGeting = true;
                this.disable = true;
                this.getCode = this.count-- + "s后重发";
              }
            }, 1000);
            return true;
          } else {
            this.$message({
              //element的message方法 前端弹窗显示
              message: res.data.status
                ? res.data.status
                : "发送验证码失败，请联系管理员",
              type: "error",
            });
            return false;
          }
        })
        .catch((err) => {
          this.$router.push("/resetPassword");
        });
    },
    submitForm(formname) {
      if (this.resetBody.password !== this.resetBody.password2) {
        this.$message.error("两次密码输入不一致，请重新输入！");
        return;
      }
      let passwordOrigin;
      this.$refs[formname].validate((valid) => {
        if (valid) {
          // try {
          passwordOrigin = this.resetBody.password;
          this.resetBody.password = this.$getRsaCode(this.resetBody.password);
          this.resetBody.password2 = this.resetBody.password;
          console.log("请求：" + JSON.stringify(this.resetBody));
          this.$axios({
            url: "/api/user/resetPassword",
            data: this.resetBody,
            method: "post",
          })
            //有一点需要注意的是，axios中POST的默认请求体类型为Content-Type:application/json（JSON规范流行），
            //这也是最常见的请求体类型，也就是说使用的是序列化后的json格式字符串来传递参数，
            //如：{ “name” : “mike”, “sex” : “male” }；同时，后台必须要以支持@RequestBody的形式接收参数，否则会出现前台传参正确，后台接收不到的情况。
            .then((res) => {
              if (res.data.code !== 200) {
                this.$message({
                  //element的message方法 前端弹窗显示
                  message: res.data.status ? res.data.status : "密码修改失败",
                  type: "error",
                });
                this.resetBody.password = "";
                this.resetBody.password2 = "";
                this.$router.push("/resetPassword");
                return false;
              }
              this.$message({
                //element的message方法 前端弹窗显示
                message: "密码修改成功",
                type: "success",
              });
              this.$router.push("/login");
              return true;
            })
            .catch((err) => {
              this.resetBody.password = "";
              this.resetBody.password2 = "";
              console.log(JSON.stringify(this.resetBody));
              this.$router.push("/resetPassword");
              return false;
            });
        } else {
          console.log(this.resetBody);
          console.log("错误的提交");
          return false;
        }
      });
    },
  },
};
</script>

<style scoped>
.sendCode {
  margin-top: 15px !important;
}
.register {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(../assets/logo.png) no-repeat center center;
  background-size: 100%;
}
.form_container {
  width: 470px;
  height: 400px;
  position: fixed;
  left: 50%;
  top: 40%;
  z-index: 11;
  /*设定这个div的margin-top的负值为⾃⾝的⾼度的⼀半,margin-left的值也是⾃⾝的宽度的⼀半的负值.*/
  /*宽为400,那么margin-top为-200px*/
  /*⾼为200那么margin-left为-100px;*/
  margin: -200px 0 0 -235px;
  padding: 25px;
  border-radius: 5px;
  text-align: center;
}
.form_container .manage_tip .title {
  font-family: "Microsoft YaHei";
  font-weight: bold;
  font-size: 26px;
  color: black;
}
.registerForm {
  margin-top: 20px;
  background-color: #fff;
  padding: 20px 40px 20px 20px;
  border-radius: 10px;
  box-shadow: 0px 5px 10px #cccc;
}
.tiparea {
  text-align: right;
  font-size: 12px;
  color: #333;
}
.tiparea p a {
  color: #409eff;
}
</style>