import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router'
import store from '../store'
import decode from "jwt-decode";
// import VueRouter from 'vue-router'
import Register from '../views/Register'
import EmailSent from '../views/EmailSent'
import EmailConfirm from '../views/EmailConfirm'
import ResetPassword from '../views/ResetPassword'
import Feedback from '../views/Feedback'
import FeedbackSuccess from '../views/FeedbackSuccess'
import Login from '../views/Login'
import NotFound from '../views/404'

import routerWhiteList from '../assets/routerWhiteList'
import { next } from 'dom7';


const routes = [
  {
    path: '/',
    redirect: "/clusterList",
  },
  {
    path: '/index',
    name: 'Home',
    component: () => import('../views/Home.vue'),
    children: [
      {
        path: '/clusterList',
        name: 'clusterList',
        component: () => import("../components/Common/ClusterList.vue")
      },
      {
        path: '/node',
        name: 'node',
        component: () => import("../components/Forms/Huawei/HuaweiNodePlus.vue")
      },
      {
        path: '/about',
        name: 'About',
        component: () => import('../components/Common/UserInfo.vue')
      },
      {
        path: '/problem',
        name: 'Problem',
        component: () => import('../components/Common/ProblemFeedback.vue')
      },
      {
        path: '/error',
        name: 'Error',
        component: () => import('../components/Common/Error.vue')
      },
      {
        path: '/form',
        name: 'form',
        component: () => import('../components/Common/Form.vue'),
        children: [
          //channel
          {
            path: 'channel',
            name: 'channel',
            component: () => import('../components/Common/Channel.vue'),
          },
          //huawei
          {
            path: 'huaweiAccess',
            name: 'huaweiAccess',
            component: () => import('../components/Forms/Huawei/HuaweiAccess.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.cloud === "huawei") {
                next()
              } else {
                next(from)
              }
            }
          },
          {
            path: 'huaweiAccess/huaweiCluster',
            name: 'huaweiCluster',
            component: () => import('../components/Forms/Huawei/Huawei.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.accessKeySecret && store.getters.reqData?.accessKeyID) {
                next();
              } else {
                next(from);
              }
            }
          },
          {
            path: 'huaweiAccess/huaweiCluster/node',
            name: 'huaweiNode',
            component: () => import('../components/Forms/Huawei/HuaweiNodePlus.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.driverOptions) {
                next();
              } else {
                next(from);
              }
            }
          },

          //ali
          {
            path: 'aliAccess',
            name: 'aliAccess',
            component: () => import('../components/Forms/Ali/AliAccess.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.cloud === "ali") {
                next()
              } else {
                next(from)
              }
            }
          },
          {
            path: 'aliAccess/aliCluster',
            name: 'aliCluster',
            component: () => import('../components/Forms/Ali/Ali.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.accessKeySecret && store.getters.reqData?.accessKeyID) {
                next();
              } else {
                next(from);
              }
            }
          },
          {
            path: 'aliAccess/aliCluster/node',
            name: 'aliiNode',
            component: () => import('../components/Forms/Ali/AliNodePlus.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.driverOptions) {
                next();
              } else {
                next(from);
              }
            }
          },

          {
            path: 'appCommonConfig',
            name: 'appCommonConfig',
            component: () => import('../components/Forms/AppConfig/AppCommonConfig.vue'),
            beforeEnter: (to, from, next) => {
              if (store.getters.reqData.nodesOptions.length > 0) {
                next();
              } else {
                next(from);
              }
            }
          },
          {
            path: 'appCustomConfig',
            name: 'appCustomConfig',
            component: () => import('../components/Forms/AppConfig/AppCustomConfig.vue'),
            beforeEnter: (to, from, next) => {
              if (Object.keys(store.getters.reqData.appCommonConfig).length > 0) {
                next();
              } else {
                next(from);
              }
            }
          },
          {
            path: "appConfigEdit",
            name: "AppConfigEdit",
            component: () => import("../components/Forms/AppConfig/AppConfigEdit.vue"),
          },
          {
            path: 'huaweiAccess/huaweiCluster/node/BuildingStatus',
            name: 'BuildingStatus',
            component: () => import('../components/Common/BuildingStatus.vue'),
            beforeEnter: (to, from, next) => {
              if (from.fullPath === "/form/appCustomConfig" || from.fullPath === "/form/appCustomConfig/" || from.fullPath === "/" || from.fullPath === "/form/appConfigEdit") {
                next()
              } else {
                next(from)
              }
            }
          },

        ]
      },
      {
        path: '/buildingStatus',
        name: 'buildingStatus',
        component: () => import('../components/Common/BuildingStatus.vue')
      },
    ]
  },
  {
    path: '/register',
    name: 'register',
    component: Register
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/emailSent',
    name: 'emailSent',
    component: EmailSent
  },
  {
    path: '/emailConfirm',
    name: 'emailConfirm',
    component: EmailConfirm
  },
  {
    path: '/resetPassword',
    name: 'resetPassword',
    component: ResetPassword
  },
  {
    path: '/feedback',
    name: 'feedback',
    component: Feedback
  },
  {
    path: '/feedbackSuccess',
    name: 'feedbackSuccess',
    component: FeedbackSuccess
  },
  {
    path: '/:pathMatch(.*)*',
    name: "404",
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  // history: createWebHistory(process.env.BASE_URL),
  routes: routes
})

router.beforeEach((to, from, next) => {
  document.title = "Alpha IDE"
  const isLogin = localStorage.eleToken ? true : false
  if (routerWhiteList.has(to.path)) {
    // if(to.path.indexOf("feedback")!==-1){
    //   console.log(to.path.substring(to.path.indexOf("username=")))
    //   next('/feedback?'+to.path.substring(to.path.indexOf("username=")))
    // }
    next()
    console.log("66666login")
  } else {
    // isLogin ? next() : next('/login')
    if (isLogin) {
      if (to.path === '/problem') {//问题反馈
        let rightLevel = decode(localStorage.getItem("eleToken")).rightLevel
        let message = "您当前还没有查看权限，若需要请找管理员进行开通哦!"
        if (rightLevel > 2) {
          next()
        } else {
          next(
            {
              path: '/error',
              query: { message: message }
            }
          )
        }
      } else {
        next()
      }
    }
    else {
      next('/login')
    }
  }

})

// const originalPush = VueRouter.prototype.push
//    VueRouter.prototype.push = function push(location) {
//    return originalPush.call(this, location).catch(err => err)
// }
export default router