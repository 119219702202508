<template>
  <div class="loading">
    <van-loading type="spinner" color="#1989fa" />
  </div>
</template>
<style scoped>
.loading {
  width: 200px;
  height: 200px;
  position: fixed; /*绝对定位*/
  top: 50%; /*距顶部50%*/
  left: 50%;
  margin: -50px 0 0 -50px; /*设定这个div的margin-top的负值为自身的高度的一半,margin-left的值也是自身的宽度的一半的负值.(感觉在绕口令)*/
  z-index: 99; /*浮动在最上层 */
}
</style>